import React, { Fragment } from 'react';
import { useLocalisation } from './localisation';

const Main = () => {
    const config = useLocalisation('main');
    return (
        <main className="page page--padding privacy-page">
            <div className="container">
                {config.map((x, i) => (
                    <Fragment key={i}>
                        <div className="main-title">{x.title}</div>
                        <div className="main-text">
                            {x.body.map((y, j) => (
                                <p key={j}>{y}</p>
                            ))}
                        </div>
                    </Fragment>
                ))}
            </div>
        </main>
    );
}

export default Main;
