import React from 'react';
import Header from './Header';
import Main from './Main';
import Footer from './Footer';
import {
  HashRouter,
  Switch,
  Route
} from "react-router-dom";

function App() {
  return (
    <HashRouter>
      <Switch>
        <Route path="/:lang?">
          <Header />
          <Main />
          <Footer />
        </Route>
      </Switch>
    </HashRouter>
  );
}

export default App;
