import { useParams } from 'react-router-dom';
import langs from './lang.json';
import en from './en.json';
import ru from './ru.json';
import fr from './fr.json';
import de from './de.json';
import es from './es.json';
import it from './it.json';
import ja from './ja.json';
import ko from './ko.json';
import pt from './pt.json';
import tw from './tw.json';
import zn from './zn.json';

const map = {
    en,
    ru,
    fr,
    de,
    es,
    it,
    ja,
    ko,
    pt,
    tw,
    zn
};

export const getUserLang = () => {
    const [lang, locale] = (navigator.userLanguage || navigator.language)
            .replace('-', '_')
            .toLowerCase()
            .split('_');
    return {
        lang,
        locale
    }
}

export const useGetLangs = () => {
    const { lang } = useParams();
    const { lang: browserLang, locale } = getUserLang();
    return {
        available: langs
            .filter(x => lang ? 
                x.code !== lang 
                : x.code !== browserLang && x.code !== `${browserLang}-${locale}`
            ),
        current: (
            langs.find(x => x.code === lang)
            || langs.find(x => x.code === browserLang)
            || langs.find(x => x.code === `${browserLang}-${locale}`)
            || langs.find(x => x.option === 'EN')
        )
    }
}

export const useLocalisation = (key) => {
    const { lang } = useParams();
    const { lang: browserLang, locale } = getUserLang();
    return (
        map[lang] 
        || map[browserLang] 
        || map[`${browserLang}-${locale}`] 
        || en
    )[key];
}

export const useIcon = () => {
    const { lang } = useParams();
    const { lang: browserLang, locale } = getUserLang();
    return (
        langs.find(x => x.code === lang)
        || langs.find(x => x.code === browserLang)
        || langs.find(x => x.code === `${browserLang}-${locale}`)
        || langs[0]
    ).icon;
}
