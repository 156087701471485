import React from 'react';
import { Link } from 'react-router-dom';
import { useGetLangs } from './localisation';

const Selector = ({type}) => {
    const langs = useGetLangs();
    return (
        <div className="nav__item switch-lang">
            <div className="switch-lang__icon">
                <img alt="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo0QzMyMzZFODgzNzkxMUU5QkYzQ0M4NTFBRjExQUY5NCIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo0QzMyMzZFOTgzNzkxMUU5QkYzQ0M4NTFBRjExQUY5NCI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjRDMzIzNkU2ODM3OTExRTlCRjNDQzg1MUFGMTFBRjk0IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjRDMzIzNkU3ODM3OTExRTlCRjNDQzg1MUFGMTFBRjk0Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+14FD1wAAAeVJREFUeNqslbFOAkEQhu9MQI0YRBpUChVLY4ImxsTGDu2MHT4ADS+g9Fr7FFpop4mWlIQOKNDEWAAGMQFEYwEYzn/wv2TdLFLIJF9u79+Z2bu5vVnbcRxrgAVAHOyBNRACHVABj+AWXICmMVoSa0yAY9B0hpv4pMCknkdPuggKSuAH+AKnwEtk3AZvil+BscbEYVCmYxHsgAyTRBW/KLUMfYqMKTPHr8Ty+lk6pME0CIIOqBrKVQNdMEvfNGOzbllcxyPlSf3U9hl8aUh8zUVjvPcrTy7fx7JlVXzDJ+C3RmMtsDzGLTWqpBZzxSVxjEIC2AoF0AOrmm5T69FH1RPMtSv1KbE2Ea2ODeoBQ41F64G6pkcYU5Iat7GCF4zzz3JNdA/1rva6Hs53Oe+al3pnjANLc7CU1xtkf85L4irH89rcJ68+Q5xP83EtzGtNEj/wZlNzGrSgqlU1fZvXnBQ86Yzeku4PUrdGa0EpRQOkKNyDGX6UOLUrwz6+4dwB7yWmSC3Vz8n9J40jrzWhBd6/GPbxK+dCWhMq6E1IWAHPSjPaUnrzuuK3QS1HH7f5VJjD2OiXlGTyZ71zfAI8YAqcUWvRZ2ijV8uS+u/RZA85TA/7DeXnMJ1T9m4e3IHzQYfptwADADSKhhItR3VzAAAAAElFTkSuQmCC" />
            </div>
            <div className="switch-lang__space"></div>
            <div className="din-condensed upper switch-lang__text">{langs.current.option}</div>
            <img alt="" src={`/media/${type}.png`} className={`switch-lang__arrow switch-lang__arrow_${type}`} />
            <div className={`switch-lang__options switch-lang__options-${type}`}>
                {type === 'down' && <img alt="" src="/media/up.png" className="switch-lang__arrow-up"/>}
                {langs.available.map(x => (
                    <Link 
                        className="switch-lang__option-text din-condensed upper" 
                        to={`/${x.code}`}
                        key={x.code}
                    >
                        {x.option}
                    </Link>
                ))}
                {type === 'up' && <img alt="" src="/media/down.png" className="switch-lang__arrow-down"/>}
            </div>
        </div>
    );
}

export default Selector;