import React from 'react';
import Selector from './Selector';
import { useLocalisation, useIcon } from './localisation';

const Header = () => {
    const support = useLocalisation('support');
    const icon = useIcon();
    return (
        <header>
            <nav className="nav" id="nav">
                <div className="container fl">
                    <a 
                        className="nav__item nav__item--logo" 
                        href="https://kefirgames.ru/"
                    >
                        <img alt="" src={`/media/${icon}`} />
                    </a>
                    <div className="fl-auto"/>
                    <a 
                        className="nav__item din-condensed upper" 
                        href="https://kefirgames.ru/support"
                    >
                        {support}
                    </a>
                    <Selector type="down"/>
                </div>
            </nav>
        </header >
    );
}

export default Header;
