import React from 'react';
import Selector from './Selector';
import { useLocalisation, useIcon } from './localisation';

const Footer = () => {
    const support = useLocalisation('support');
    const icon = useIcon();
    return (
        <>
            <div className="page__filler"></div>
            <footer className="footer main-text white-color">
                <div className="container">
                    <nav className="footer__nav fl mt-2">
                        <a 
                            className="nav__item nav__item--logo" 
                            href="https://kefirgames.ru/"
                        >
                            <img alt="" src={`/media/${icon}`} />
                        </a>
                        <div className="fl-auto"/>
                        <a 
                            className="nav__item din-condensed upper" 
                            href="https://kefirgames.ru/support"
                        >
                            {support}
                        </a>
                        <Selector type="up"/>
                    </nav>
                </div>
            </footer>
        </>
    );
}

export default Footer;
